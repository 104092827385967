import React from "react";
import { Container, Row, Col } from "react-bootstrap-v5";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import "swiper/css";
import TWK from "../images/twk.webp";
import Maidan from "../images/maidan.webp"
import Etsybrand from "../images/etsybrand.webp"
import IRG from "../images/irg-global.webp"
import Imperial from "../images/Imperial-Internet.webp"
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import "../Pages/testimonial.css";

const data = [
  {
    id: 1,
    image: Maidan,
    title: "Maidan Offical",
    msg: "Lorem ipsum dolor sit, amet consectetur adipisicing elit.Lorem ipsum dolor sit, amet consectetur adipisicing elit.Lorem ipsum dolor sit, amet consectetur adipisicing elit.",
  },
  {
    id: 2,
    image: TWK,
    title: "Train with Kaka",
    msg: "Lorem ipsum dolor sit, amet consectetur adipisicing elit.Lorem ipsum dolor sit, amet consectetur adipisicing elit.Lorem ipsum dolor sit, amet consectetur adipisicing elit.",
  },
  {
    id: 3,
    image: Etsybrand,
    title: "Etsybrand",
    msg: "Lorem ipsum dolor sit, amet consectetur adipisicing elit.Lorem ipsum dolor sit, amet consectetur adipisicing elit.Lorem ipsum dolor sit, amet consectetur adipisicing elit.",
  },
  {
    id: 4,
    image: IRG,
    title: "IRG Global",
    msg: "Lorem ipsum dolor sit, amet consectetur adipisicing elit.Lorem ipsum dolor sit, amet consectetur adipisicing elit.Lorem ipsum dolor sit, amet consectetur adipisicing elit.",
  },
  {
    id: 5,
    image: Imperial,
    title: "Imperial Internet",
    msg: "Lorem ipsum dolor sit, amet consectetur adipisicing elit.Lorem ipsum dolor sit, amet consectetur adipisicing elit.Lorem ipsum dolor sit, amet consectetur adipisicing elit.",
  },
];

const Partners = () => {
  return (
    <>
      <Container fluid className="mt-5 mb-5">
      <Container id="clients" className="pt-5 pb-5">
        <h1 className="text-center">Clients</h1>
        <Swiper
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          spaceBetween={3}
          slidesPerView={4}
          
          navigation
          breakpoints={{
            320: {
              slidesPerView: 1,
            },
            480: {
              slidesPerView: 2,
            },
            640: {
              slidesPerView: 3,
            },
            992: {
              slidesPerView: 4,
            },
          }}
          onSlideChange={() => console.log("slide change")}
        >
          {data.map(({ id, image, title, msg }) => {
            return (
              <SwiperSlide className="mt-5 mb-5" key={id}>
                <Row className=" d-flex justify-content-center">
                  <Col
                    md={8}
                    sm={12}
                    className="d-flex flex-column align-items-center p-3 "
                  >
                    <div className="AA d-flex justify-content-center align-items-center">
                      <img src={image} alt="" className="img-fluid" />
                    </div>
                    <h6 className="text-center text-white fw-bold mt-3">{title}</h6>
                    {/* <p className="text-center text-white">{msg}</p> */}
                  </Col>
                </Row>
              </SwiperSlide>
            );
          })}
        </Swiper>
        {/* <iframe src='https://widgets.sociablekit.com/google-reviews/iframe/25379547' frameborder='0' width='100%' height='1000'></iframe> */}
      </Container>
      </Container>
    </>
  );
};

export default Partners;
