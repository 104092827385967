import React from "react";
// import { Container, Row, Col } from "react-bootstrap-v5";
import TypeWriter from "typewriter-effect";
import CTA from "../Component/CTA";
import ME from "../images/sadaada.webp";
import Social from "../Component/social";
import StarField from "@dvlpralamin/star-field";

const Hero = () => {
  return (
    <header className="im position-relative" >
      <StarField className="" />
      <div className="container header_container ">
        <h5>hello I'm</h5>
        <h2 className="text-light">Anas Rasheed</h2>
        <h2>
          <TypeWriter
            options={{
              strings: ["Frontend Developer","Website Designer","Wordpress Developer"],
              autoStart: true,
              loop: true,
            }}
          />
        </h2>
        <CTA />
        <Social />
        <div className=" me">
          <img src={ME} alt="me" className="img-fluid " />
        </div>
        <a href="#contact" className="scroll_down text-decoration-none">
          Scroll Down
        </a>
      </div>
    </header>
  );
};

export default Hero;
