import React from "react";
import "../Pages/about.css";
import { Container, Card, Col, Row } from "react-bootstrap-v5";
import ME from "../images/me1.webp";
import { FaAward } from "react-icons/fa";
import { FiUser } from "react-icons/fi";
import { VscFolderLibrary } from "react-icons/vsc";
import CountUp from "react-countup";

const About = () => {
  return (
    <Container fluid id="about">
      <Container className="pt-5 pb-5">
        <h5 className="text-white">Get To Know</h5>
        <h2 className="about-h2 text-center">About Me</h2>
        <Row className="mt-3 g-5">
          <Col md={5} sm={12}>
            <img
              src={ME}
              alt="About"
              className="about_me_image img-fluid  rounded"
            />
          </Col>
          <Col md={7} sm={12} className="text-start">
            <Row className="g-3">
              <Col md={4} sm={12}>
                <Card className=" about_card ">
                  <FaAward className="about_icon " />
                  <h4 className="mt-4">Experience</h4>
                  <small>
                    <CountUp start={0} end={2} delay={1} /> Year working
                  </small>
                </Card>
              </Col>
              <Col md={4} sm={12}>
                <Card className=" about_card ">
                  <FiUser className="about_icon " />
                  <h4 className="mt-4">Clients</h4>
                  <small>
                    <CountUp start={0} end={10} delay={1} /> Worldwide
                  </small>
                </Card>
              </Col>
              <Col md={4} sm={12}>
                <Card className=" about_card">
                  <VscFolderLibrary className="about_icon " />
                  <h4 className="mt-4">Projects</h4>
                  <small>
                    <CountUp start={0} end={50} delay={1} /> Completed
                  </small>
                </Card>
              </Col>
            </Row>
            <p className="text-start mt-4">
              Good day, Thanks for visiting my portfolio. Hey there! I'm
              Muhammad Anas, and I'm a web developer from Lahore, Pakistan. My
              expertise lies in creating interactive digital experiences that
              are customized to match the specific requirements of individuals
              and businesses, drawing upon my extensive knowledge in website
              design and development. My experience as a web developer has given
              me a wide range of skills, including the ability to utilise
              WordPress, JavaScript, HTML5, CSS, Bootstrap, and React.js.
              Turning ideas into reality through precise coding and a passion
              for innovation is my forte, whether it's making aesthetically
              gorgeous layouts, incorporating interactive features, or perfectly
              tweaking WordPress themes.
            </p>
            <a href="#contact" className="btn btn-primary mt-3">
              Let's Talk
            </a>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default About;
