import React from 'react'
import {useState} from "react";
import "../Component/footer.css";
import Footerlogo from "../images/anas-logo1.webp"
const footer = () => {
  return (
    <>
    <footer className='pt-5 pb-5'>
     <div className='text-center  d-flex justify-content-center'><span className=' d-flex justify-content-center'><img src={Footerlogo} alt="" className='img-fluid w-50'  /></span></div>
     <div className='text-center pb-5'>
          <small>© 2024 Anas Rasheed ❤️ All Rights Reserved.</small>
        {/* <ul className='lh-lg footer-li pt-3 pb-5'>
            <li>Home</li>
            <li>About Us</li>
            <li>Experience</li>
            <li>Portfolio</li>
            <li>Testimonial</li>
            <li>Contact</li>
        </ul> */}
     </div>
    </footer>
    </>
  )
}

export default footer