import React, { useRef, useState } from "react";
import { Container, Col, Row, Card, Alert } from "react-bootstrap-v5";
import { BsFillEnvelopeFill, BsLinkedin, BsWhatsapp } from "react-icons/bs";
import emailjs from "emailjs-com";
import "../Pages/contact.css";
import StarField from "@dvlpralamin/star-field";

const Contact = () => {
  const form = useRef();
  const [showSuccess, setShowSuccess] = useState(false);

  const sendEmail = async (e) => {
    e.preventDefault();

    try {
      await emailjs.sendForm(
        "service_vafyv48",
        "template_bs9t0sm",
        form.current,
        "JtMVAIiM37v8vFSvR"
      );
      setShowSuccess(true);
      e.target.reset();
      setTimeout(() => {
        setShowSuccess(false);
      }, 2000); // 2000 milliseconds = 2 seconds
    } catch (error) {
      console.error("Error sending email:", error);
    }
  };

  return (
    <>

      <Container fluid className="pt-5 pb-5 position-relative" >
      <Container className="mt-5 mb-5 overflow-hidden" id="contact">
        <StarField className="" />
        <h5 className="text-white">Get In Touch</h5>
        <h2 className="about-h2 text-center">Contact Me</h2>
        <Row className="mt-5 g-5 ">
          <Col md={6} sm={12}>
            <Row className="g-4 d-flex justify-content-center ">
              <Col md={8} sm={8}>
                <a
                  href="mailto:anasrasheed161@gmail.com"
                  className="text-decoration-none"
                >
                  <Card className="text-center d-flex flex-column align-items-center p-3 contact-bg">
                  
                    <BsFillEnvelopeFill className="fs-3 text-white" />
                    <h5 className="text-center mt-1 text-white">Email</h5>
                    <a
                      href="mailto:anasrasheed161@gmail.com"
                      className="text-white text-decoration-none"
                    >
                      anasrasheed161@gmail.com
                    </a>
                    <a href="" className="text-decoration-none contact-msg">
                      Send a Message
                    </a>
                  </Card>
                </a>
              </Col>
              <Col md={8} sm={8}>
                <a
                  href="https://www.linkedin.com/in/anas-rasheed-86aa50313/"
                  className="text-decoration-none"
                >
                  <Card className="text-center d-flex flex-column align-items-center p-3 contact-bg">
                    <BsLinkedin className="fs-3 text-white" />
                    <h5 className="text-center mt-2 text-white">Linkedin</h5>
                    <a
                      href="https://www.linkedin.com/in/anas-rasheed-86aa50313/"
                      className="text-white text-decoration-none"
                    >
                      Anas Rasheed
                    </a>
                    <a href="https://www.linkedin.com/in/anas-rasheed-86aa50313/" className="text-decoration-none contact-msg">
                      Send a Message
                    </a>
                  </Card>
                </a>
              </Col>
              <Col md={8} sm={8}>
                <a href="https://api.whatsapp.com/send?phone=+923094288583&text=Hello%20I%20want%20information%20about%20your%20services" className="text-decoration-none">
                  <Card className="text-center d-flex flex-column align-items-center p-3 contact-bg">
                    <BsWhatsapp className="fs-3 text-white" />
                    <h5 className="text-center mt-2 text-white">Whatsapp</h5>
                    <a
                      href="https://api.whatsapp.com/send?phone=+923094288583&text=Hello%20I%20want%20information%20about%20your%20services"
                      className="text-white text-decoration-none"
                    >
                      0309 4288583
                    </a>
                    <a href="https://api.whatsapp.com/send?phone=+923094288583&text=Hello%20I%20want%20information%20about%20your%20services" className="text-decoration-none contact-msg">
                      Send a Message
                    </a>
                  </Card>
                </a>
              </Col>
            </Row>
          </Col>
          <Col
            md={6}
            sm={12}
            className="d-flex flex-column  justify-content-center"
          >
            {showSuccess && (
              <Alert variant="success">Email sent successfully!</Alert>
            )}
            <form className="row g-3" ref={form} onSubmit={sendEmail}>
              <div className="col-md-12">
                <label className="form-label">Name</label>
                <input
                  name="name"
                  type="text"
                  className="form-control w-full form-control-lg border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                />
              </div>
              <div className="col-md-12">
                <label className="form-label">Email address</label>
                <input
                  name="email"
                  type="email"
                  className="form-control w-full form-control-lg border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                />
              </div>
              <div className="col-md-12">
                <label className="form-label">Message</label>
                <textarea
                  name="message"
                  className="form-control h-100 w-full form-control-lg border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 required"
                />
              </div>
              <div className="col-12 mt-5">
                <button
                  type="submit"
                  className="btn px-5 text-indigo-100 bg-indigo-700 rounded-lg transition-colors duration-150 focus:shadow-outline hover:bg-indigo-800"
                >
                  Subscribe
                </button>
              </div>
            </form>
          </Col>
        </Row>
      </Container>
      </Container>
    </>
  );
};

export default Contact;
