import React from "react";
import "../Pages/services.css";
import { Container, Row, Col } from "react-bootstrap-v5";
import Cards from "react-animated-3d-card-shadow";
import ico from "../images/Web-Development.webp";
import Wordpress from "../images/Wordpress.webp"
import Responsive from "../images/Responsive-Web-Design.webp"
import { Card } from "react-bootstrap-v5";

const Services = () => {
  const items = [
    {
      id: 1,
      title: "Web Development",
      description: "I am an expert front-end web developer with knowledge of HTML5, CSS3, JavaScript, Bootstrap, and React JS. I build eye-catching and user-friendly websites.",
      image: ico,
    },
    {
      id: 2,
      title: "Wordpress Development",
      description: "WordPress developer with experience focusing on blogging, e-commerce, and corporate websites. Dedicated to flawless operation and customized user experiences.",
      image: Wordpress,
    },
    {
      id: 3,
      title: "Responsive Designs",
      description: "Expertise in responsive and optimized web creation, which makes sure that the site looks great on all devices. Check out my work for great results and great user experiences!",
      image: Responsive,
    },
  ];

  return (
    <Container className="pt-5 pb-5" id="services">
      <h3 className="text-white text-center">What Services I Have</h3>
      {/* <h2 className="text-center experience_h2">My Experience</h2> */}
      <Row className=" g-4 p-2  ">
        {items.map(({ id, image, title, description }) => {
          return (
            <Col md={4} sm={12} key={id}>
            
            <div>
            <Cards className="services-card text-dark bg-dark">
             <div className="services-card ">
               <div className=" d-flex justify-content-center">
               <Card.Img variant="top" className="w-50" src={image} />
               </div>
                <Card.Body>
                  <Card.Title className="text-center service-heading">{title}</Card.Title>
                  <Card.Text className="text-center">{description}</Card.Text>
                </Card.Body>
                </div>  
              </Cards>
            </div>
            </Col>
          );
        })}
      </Row>
    </Container>
  );
};

export default Services;