import React, { useState } from "react";
import "../Pages/portfolio.css";
import { BsBoxArrowUpRight } from "react-icons/bs";
import { Container, Row, Col } from "react-bootstrap-v5";
import Generic from "../images/generic.webp";
import Customized from "../images/customized.webp";
import Imperialmobile from "../images/imperialmobile.webp";
import Authorized from "../images/authorizedinternet.webp";
import Maidan from "../images/maidanofficial.webp";
import fiberinyourarea from "../images/fiberinyourarea.png";
import hdmovie from "../images/hdmovie.webp";
import Cards from "react-animated-3d-card-shadow";
import Irgglobal from "../images/irg-global-web.webp";
import Lootlobazaar from "../images/lootlobazzar.webp";
import Advertisinghands from "../images/advertisinghands.webp";
import Imperialinternet from "../images/imperialinternet.webp";
import Trainwithkaka from "../images/trainwithkaka.webp";
import { motion } from "framer-motion";

const Portfolio = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [category, setCategory] = useState("all");

  const data = [
    {
      id: 1,
      image: Lootlobazaar,
      title: "Lootlo bazaar",
      github: "",
      demo: "https://lootlobazaar.pk/",
      category: "wordpress",
    },
    {
      id: 2,
      image: Customized,
      title: "Customize Box Packaging",
      github: "",
      demo: "https://customizeboxpackaging.com/",
      category: "custom",
    },
    {
      id: 3,
      image: Imperialmobile,
      title: "Imperial Mobile",
      github: "",
      demo: "https://imperialmobile.com/",
      category: "wordpress",
    },
    {
      id: 4,
      image: Authorized,
      title: "Authorized Internet",
      github: "",
      demo: "https://authorizedinternet.com/",
      category: "wordpress",
    },
    {
      id: 5,
      image: Maidan,
      title: "Maidan Offical",
      github: "",
      demo: "https://maidanofficial.com/",
      category: "wordpress",
    },
    {
      id: 6,
      image: Irgglobal,
      title: "IRG Global",
      github: "",
      demo: "https://irgglobal.co/",
      category: "wordpress",
    },
    {
      id: 7,
      image: fiberinyourarea,
      title: "Fiber In your Area",
      github: "",
      demo: "",
      category: "custom",
    },
    {
      id: 8,
      image: hdmovie,
      title: "Watch Hd Movies",
      github: "",
      demo: "",
      category: "wordpress",
    },
    {
      id: 9,
      image: Generic,
      title: "Generic Growth",
      github: "",
      demo: "https://www.genericgrowth.com/",
      category: "custom",
    },
    {
      id: 12,
      image: Advertisinghands,
      title: "Advertising Hands",
      github: "",
      demo: "https://advertisinghands.com/",
      category: "custom",
    },
    {
      id: 13,
      image: Imperialinternet,
      title: "Imperial Internet",
      github: "",
      demo: "https://imperialinternet.com/",
      category: "Shopify",
    },
    {
      id: 14,
      image: Trainwithkaka,
      title: "Imperial Internet",
      github: "",
      demo: "https://trainwithkaka.com/",
      category: "custom",
    },
  ];

  const filteredData = data.filter(
    ({ title, category: projectCategory }) =>
      title.toLowerCase().includes(searchTerm.toLowerCase()) &&
      (category === "all" || category === projectCategory)
  );

  return (
    <>
      <Container fluid>
        <Container className="portfolio_container pb-5 pt-5" id="portfolio">
          <h5 className="text-white">My Recent Work</h5>
          <h1 className="text-center about-h2">Portfolio</h1>

          <div className="d-flex justify-content-center mt-5">
            <input
              className="btn"
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search projects..."
            />
          </div>

          <div className="d-flex justify-content-center mt-3">
            <button
              className={`btn me-2 ${category === "all" ? "active" : ""}`}
              onClick={() => setCategory("all")}
            >
              All
            </button>
            <button
              className={`btn me-2 ${category === "wordpress" ? "active" : ""}`}
              onClick={() => setCategory("wordpress")}
            >
              WordPress
            </button>
            <button
              className={`btn ${category === "custom" ? "active" : ""}`}
              onClick={() => setCategory("custom")}
            >
              {" "}
              Custom
            </button>
          </div>

          <Row className="g-3 mt-3">
            {filteredData.map(
              ({ id, image, title, github, demo, category }) => (
                <Col
                  md={4}
                  sm={12}
                  className="d-flex justify-content-center"
                  key={id}
                >
                  <motion.div>
                    <Cards className="portfolio_item w-100">
                      <div className="portfolio_item w-100 ">
                        <div className="portfolio_img overflow-hidden">
                          <div className="image-container rounded-3 w-100">
                            <img
                              src={image}
                              alt={title}
                              className="hover-image rounded-3 w-100"
                            />
                          </div>
                        </div>
                        <h5 className="mt-3 text-white">{title}</h5>

                        <div className="mt-3 d-flex justify-content-between">
                          <div className="d-flex align-items-center"><small className="category">{category}</small></div>
                          <a href={demo} className="fs-5">
                          <BsBoxArrowUpRight className="text-white" />
                          </a>
                        </div>
                      </div>
                    </Cards>
                  </motion.div>
                </Col>
              )
            )}
          </Row>
        </Container>
      </Container>
    </>
  );
};

export default Portfolio;
