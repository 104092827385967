import React from "react";
import "../Pages/experience.css";
import { ProgressBar } from "react-bootstrap-v5";
import { AiFillHtml5 } from "react-icons/ai";
import { FaCss3Alt, FaReact, FaWordpress,FaGithub } from "react-icons/fa";
import { IoLogoJavascript } from "react-icons/io";
import { BsFillBootstrapFill } from "react-icons/bs";
import { BiLogoTailwindCss } from "react-icons/bi";
import Vvorklogo from "../images/vvork.webp";
import Irg from "../images/irg-global.webp";
import Maidan from "../images/maidan.webp";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import StarField from "@dvlpralamin/star-field";
import { Container, Row, Col } from "react-bootstrap-v5";
const skills = [
  {
    icon: <AiFillHtml5 className="fs-1 experience_icon  httml" />,
    name: "HTML5",
    percentage: 100,
  },
  {
    icon: <FaCss3Alt className="fs-1 experience_icon  csss" />,
    name: "CSS",
    percentage: 100,
  },
  {
    icon: <IoLogoJavascript className="fs-1 experience_icon  jss" />,
    name: "JavaScript",
    percentage: 80,
  },
  {
    icon: <BsFillBootstrapFill className="fs-1 experience_icon  bss" />,
    name: "Bootstrap 5",
    percentage: 100,
  },
  {
    icon: <BiLogoTailwindCss className="fs-1 experience_icon  tw" />,
    name: "Tailwind",
    percentage: 70,
  },
  {
    icon: <FaReact className="fs-1 experience_icon  csss" />,
    name: "React Js",
    percentage: 80,
  },
  {
    icon: <FaWordpress className="fs-1 experience_icon  csss" />,
    name: "Wordpress",
    percentage: 80,
  },
  {
    icon: <FaGithub  className="fs-1 experience_icon  text-white" />,
    name: "Github",
    percentage: 70,
  },
];

const Experience = () => {
  return (
    <>
      <Container fluid>
        <Container className="pt-5 pb-5" id="experinece">
          <h5 className="text-white">What Skill I Have</h5>
          <h2 className="text-center experience_h2">My Experience</h2>
          <Row className=" p-0   mt-5 d-flex justify-content-center">
          
            <Col md={11} sm={12} className="card-experience p-4">
            <h2 className="text-center mb-5">Frontend Development</h2>
              <Row className="g-5 d-flex justify-content-center">
                {skills.map((skill, index) => (
                  <Col md={4} sm={12} className=" ">
                    <div className="d-flex ">
                      {skill.icon}
                      <div className="mx-3 text-start">
                        <h4 className="m-0 p-0 text-start experience_h4">
                          {skill.name}
                        </h4>
                        <small className="experience_small ">
                          Experienced {skill.percentage}%
                        </small>
                        <ProgressBar
                          now={skill.percentage}
                          animated={skill.percentage}
                        />
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Container>
        <Container fluid className="position-relative ">
          <StarField className="" />
          <VerticalTimeline >
            <VerticalTimelineElement 
              className="vertical-timeline-element--work"
              contentStyle={{
                background: "var(--color-bg-variant)",
                color: "#fff",
              }}
              contentArrowStyle={{
                borderRight: "7px solid  var(--color-bg-variant)",
              }}
              date="2021 - 2021"
              iconStyle={{
                background: "var(--color-primary)",
                color: "#fff",
                overflow: "hidden",
              }}
              icon={<img src={Vvorklogo} alt="Vvork Cloud Technologies" />}
            >
              <h3 className="vertical-timeline-element-title">
                Frontend Developer
              </h3>
              <h6
                className="vertical-timeline-element-subtitle "
                style={{ color: "var(--color-primary)" }}
              >
                Vvork Cloud Technologies
              </h6>
              <p>
              At Vvork Cloud Technologies, my first role as a Frontend Developer is an exciting opportunity for me to delve into the world of web development, eager to absorb knowledge and grow.
              </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{
                background: "var(--color-bg-variant)",
                color: "#fff",
              }}
              contentArrowStyle={{
                borderRight: "7px solid  var(--color-bg-variant)",
              }}
              date="Dec 2022 - present"
              iconStyle={{
                background: "var(--color-bg-variant)",
                color: "#fff",
                overflow: "hidden",
              }}
              icon={<img src={Irg} alt="Vvork Cloud Technologies" />}
            >
              <h3 className="vertical-timeline-element-title">
                Frontend / Wordpress Developer
              </h3>
              <h6
                className="vertical-timeline-element-subtitle "
                style={{ color: "var(--color-primary)" }}
              >
                IRG Global
              </h6>
              <p>
                Frontend / WordPress Developer at IRG Global, specializing in
                website design, development, and management. Currently managing
                all frontend tasks and WordPress development projects.
                Experienced in Creative Direction and adept in various frontend
                technologies.
              </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{
                background: "var(--color-bg-variant)",
                color: "#fff",
              }}
              contentArrowStyle={{
                borderRight: "7px solid  var(--color-bg-variant)",
              }}
              date="Sep 2023  - present"
              iconStyle={{
                background: "rgb(33, 150, 243)",
                color: "#fff",
                overflow: "hidden",
              }}
              icon={<img src={Maidan} alt="Vvork Cloud Technologies" />}
            >
              <h3 className="vertical-timeline-element-title">
                Part-Time Remote Wordpress Handling
              </h3>
              <h6
                className="vertical-timeline-element-subtitle"
                style={{ color: "var(--color-primary)" }}
              >
                Maidan Offical
              </h6>
              <p>
                I remotely manage the Maidan Office website, handling daily
                updates and ensuring its smooth operation.
              </p>
            </VerticalTimelineElement>
          </VerticalTimeline>
        </Container>
      </Container>
    </>
  );
};

export default Experience;
