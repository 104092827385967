
import "./App.css";
import Header from "./Component/header";
import Hero from "./Pages/Hero";
import About from "./Pages/About";
import Experience from "./Pages/experience";
import Portfolio from "./Pages/portfolio";
import Partners from "./Pages/partners";
import Contact from "./Pages/contact";
import Footer from "./Component/footer"
import Services from "./Pages/services";


function App() {
  return (
 <>
    <Hero/>
    <Header/>
    <About/>
    <Services/>
    <Experience/>
    <Portfolio />
    <Partners/>
    <Contact/>
    <Footer/>
 </>
  );
}
export default App;