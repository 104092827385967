import {useState} from "react";
import React from "react";
import "../Component/header.css";
import { AiOutlineHome, AiOutlineUser } from "react-icons/ai";
import {  BiMessageSquareDetail } from "react-icons/bi";
import { BsPersonWorkspace } from "react-icons/bs";
import { FaRegImages } from "react-icons/fa";
import { GrServices } from "react-icons/gr";
// import { RiServiceLine } from "react-icons/ri";
import {MdOutlineReviews} from "react-icons/md"

const Header = () => {
  const [activeNav, setActiveNav] = useState("#");
  return (
    <nav>
       <a
        href="#"
        onClick={() => setActiveNav("#")}
        className={activeNav === "#" ? "active" : ""}
        title="Home"
      >
        <AiOutlineHome />
      </a>
      <a
        href="#about"
        onClick={() => setActiveNav("#about")}
        className={activeNav === "#about" ? "active" : ""}
        title="About"
      >
        <AiOutlineUser />
      </a>
      <a
        href="#services"
        onClick={() => setActiveNav("#services")}
        className={activeNav === "#services" ? "active" : ""}
        title="Services"
      >
        <GrServices />
      </a>
      <a
        href="#experinece"
        onClick={() => setActiveNav("#experinece")}
        className={activeNav === "#experinece" ? "active" : ""}
        title="Experience"
      >
        <BsPersonWorkspace />
      </a>
      <a
        href="#portfolio"
        onClick={() => setActiveNav("#portfolio")}
        className={activeNav === "#portfolio" ? "active" : ""}
        title="Portfolio"
      >
        <FaRegImages  />
      </a>
      <a
        href="#clients"
        onClick={() => setActiveNav("#clients")}
        className={activeNav === "#clients" ? "active" : ""}
        title="Clients"
      >
        <MdOutlineReviews />
      </a>
      <a
        href="#contact"
        onClick={() => setActiveNav("#contact")}
        className={activeNav === "#contact" ? "active" : ""}
        title="Contact"
      >
        <BiMessageSquareDetail />
      </a>
    </nav>
  );
};

export default Header;
