import React from 'react'
import { BsLinkedin, BsFacebook, BsInstagram,BsGithub } from "react-icons/bs";

const social = () => {
  return (
    <div className='social'>
      <a href="https://www.linkedin.com/in/anas-rasheed-86aa50313/" target='_blank' rel="noreferrer"><BsLinkedin /></a>
      <a href="https://www.facebook.com/anas.rasheed.50702?mibextid=ZbWKwL" target='_blank' rel="noreferrer"><BsFacebook /></a>
      <a href="https://www.instagram.com/anas_rasheed_developer?igsh=dzdqdDRrNTMxZXZi" target='_blank' rel="noreferrer"><BsInstagram /></a>
      <a href="https://github.com/Anas122334" target='_blank' rel="noreferrer"><BsGithub /></a>
    </div>
  )
}

export default social;
